/* https://contactmentor.com/how-to-add-loading-spinner-react-js/ */

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinner-container {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
