.body-container {
  background-color: ghostwhite;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.site-container {
  max-width:1200px;
  margin:auto;
  padding-top: 55px;
}

.content-container {
  border-left: 7px solid MediumSlateBlue;
  background-color: white;
  box-shadow:0 0 10px 0 rgba(0,0,0,.2);
}

 .navbar { 
  color: white; 
  background-color: darkslateblue;
}

.profile-img {
  max-width: 360px;
  width:100%;
}

.link-no-decoration {
  text-decoration: none;
}

.contact-link-container {
  height: 40px;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  justify-content: start;
}

.contact-link-text {
  margin-left: 5px;
  text-align: end;
}

.quantity-selector-input {
  display: flex;
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
}

.quantity-selector-text {
  display: flex;
  align-items: center;
  font-size: 1.25em;
}

.cart-product-container {
  border-bottom: 3px solid MediumSlateBlue;
  background-color: white;
}